@tailwind base;
@tailwind components;
@tailwind utilities;


/* scroll styles */

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #f5f9fc;
    border-radius: 50px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #7a7a7a;
    border-radius: 50px;
  }